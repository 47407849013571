import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Heading, Button, Image, Badge, Container, useColorModeValue, IconButton, Stack } from '@chakra-ui/react';
import { FaShoppingCart, FaBars, FaTimes } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';

const Navbar: React.FC = () => {
    const { isAuthenticated, logout } = useAuth();
    const { items } = useCart();
    const bgColor = useColorModeValue('white', 'gray.800');
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Box as="nav" bg={bgColor} boxShadow="sm" position="sticky" top="0" zIndex="sticky">
            <Container maxW="container.xl">
                <Flex align="center" justify="space-between" wrap="wrap" py={4}>
                    <Flex align="center" mr={5}>
                        <Image src="/mona_logo_1.png" alt="Mona Logo" boxSize="40px" mr={3} />
                        <Heading as="h1" size="lg" letterSpacing="tight" fontWeight="extrabold">
                            MONA
                        </Heading>
                    </Flex>

                    <IconButton
                        display={{ base: "flex", md: "none" }}
                        onClick={toggle}
                        icon={isOpen ? <FaTimes /> : <FaBars />}
                        variant="outline"
                        aria-label="Toggle Navigation"
                    />

                    <Box
                        display={{ base: isOpen ? "block" : "none", md: "block" }}
                        flexBasis={{ base: "100%", md: "auto" }}
                    >
                        <Stack
                            spacing={4}
                            align="center"
                            justify={["center", "space-between", "flex-end", "flex-end"]}
                            direction={["column", "row", "row", "row"]}
                            pt={[4, 4, 0, 0]}
                        >
                            <Button as={RouterLink} to="/" variant="ghost">Home</Button>
                            <Button as={RouterLink} to="/about" variant="ghost">About</Button>
                            <Button as={RouterLink} to="/contact" variant="ghost">Contact</Button>
                            <Button as={RouterLink} to="/orders" variant="ghost">Orders</Button>
                            <Button as={RouterLink} to="/cart" variant="ghost" position="relative">
                                <FaShoppingCart />
                                {items.length > 0 && (
                                    <Badge colorScheme="brand" borderRadius="full" position="absolute" top="-1" right="-1">
                                        {items.length}
                                    </Badge>
                                )}
                            </Button>
                            {isAuthenticated ? (
                                <Button onClick={logout} variant="outline" colorScheme="brand">
                                    Logout
                                </Button>
                            ) : (
                                <>
                                    <Button as={RouterLink} to="/login" variant="ghost">Login</Button>
                                    <Button as={RouterLink} to="/register" colorScheme="brand">Register</Button>
                                </>
                            )}
                        </Stack>
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
}

export default Navbar;