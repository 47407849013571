import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

api.interceptors.request.use((config) => {
    console.log('Request:', config.method?.toUpperCase(), config.url, config.data);
    return config;
});

// Response interceptor for logging
api.interceptors.response.use(
    (response) => {
        console.log('Response:', response.status, response.config.url, response.data);
        return response;
    },
    (error) => {
        console.error('Error:', error.response?.status, error.response?.config.url, error.response?.data);
        return Promise.reject(error);
    }
);


api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export const register = (email: string, password: string) =>
    api.post('/api/register', { email, password });


export const generateImages = async (prompt: string) => {
    const response = await api.post('/api/images/generate', { prompt });
    return response.data.images;
};

export const subscribeToNewsLetter = async (email: string) => {
    await api.post('/api/newsletter', {email});
};

export const getUserImages = () => api.get('/api/images');

export const createPaymentIntent = (amount: number, currency: string = 'usd') =>
    api.post('/api/payment/create-intent', { amount, currency });


export const createOrder = (imageIds: number[], total: number, customerData: any, paymentIntentId: string) =>
    api.post('/api/orders', {
        image_ids: imageIds,
        total: total,
        customer: customerData,
        payment_intent_id: paymentIntentId
    });

export const loginApi = (email: string, password: string) =>
    api.post('/api/login', { email, password });

export const getUserOrders = () => api.get('/api/orders');

export const sendUserMessage = (name: string, email: string, message: string) => api.post('/api/user-message', {
    name: name,
    email: email,
    message: message,
});

export default api;