import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: '"Poppins", sans-serif',
        body: '"Open Sans", sans-serif',
    },
    colors: {
        brand: {
            50: '#fff0e5',
            100: '#ffd9b8',
            200: '#ffc28a',
            300: '#ffab5c',
            400: '#ff942e',
            500: '#ff7d00',  // Main brand color
            600: '#cc6400',
            700: '#994b00',
            800: '#663200',
            900: '#331900',
        },
        secondary: {
            50: '#fff5e6',
            100: '#ffe0b2',
            200: '#ffcc80',
            300: '#ffb74d',
            400: '#ffa726',
            500: '#ff9800',
            600: '#fb8c00',
            700: '#f57c00',
            800: '#ef6c00',
            900: '#e65100',
        },
        neutral: {
            50: '#f2f2f2',
            100: '#d9d9d9',
            200: '#bfbfbf',
            300: '#a6a6a6',
            400: '#8c8c8c',
            500: '#737373',
            600: '#595959',
            700: '#404040',
            800: '#262626',
            900: '#0d0d0d',
        },
    },
    components: {
        Button: {
            baseStyle: {
                fontWeight: 'bold',
                borderRadius: 'md',
                transition: 'all 0.3s',
                _hover: {
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                },
            },
        },
        Card: {
            baseStyle: {
                borderRadius: 'lg',
                boxShadow: 'md',
                transition: 'all 0.3s',
                _hover: {
                    transform: 'translateY(-4px)',
                    boxShadow: 'lg',
                },
            },
        },
    },
})

export default theme