import React from 'react';
import {
    Flex,
    VStack,
    Text,
    Button,
    Icon,
    Box,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

interface CartItem {
    id: number;
    url: string;
    price: number;
}

interface CartItemProps {
    item: CartItem;
    onRemove: (id: number) => void;
}

const CartItem: React.FC<CartItemProps> = ({ item, onRemove }) => (
    <Flex
        borderWidth={1}
        borderRadius="lg"
        p={4}
        align="center"
        bg="white"
        boxShadow="sm"
        _hover={{ boxShadow: 'md' }}
        transition="all 0.3s"
    >
        <Box
            width="120px"
            height="80px"
            position="relative"
            overflow="hidden"
            borderRadius="md"
            mr={4}
        >
            <Box
                as="img"
                src={item.url}
                alt={`Image ${item.id}`}
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                objectFit="cover"
            />
        </Box>
        <VStack align="start" flex={1} spacing={1}>
            <Text fontWeight="bold" fontSize="lg">Image {item.id}</Text>
            <Text color="gray.600">Size: 24″×36″</Text>
            <Text fontWeight="semibold" color="brand.500">${item.price.toFixed(2)}</Text>
        </VStack>
        <Button
            variant="ghost"
            colorScheme="red"
            onClick={() => onRemove(item.id)}
            size="sm"
            aria-label="Remove item"
        >
            <Icon as={FaTrash} />
        </Button>
    </Flex>
);

export default CartItem;