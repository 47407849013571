import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    SimpleGrid,
    Image,
    Spinner,
    Container,
    Badge,
    Flex,
    useToast,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { getUserOrders } from '../utils/api';
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";

interface Order {
    id: number;
    status: string;
    created_at: string;
    images: Image[];
}

interface Image {
    id: number;
    url: string;
}

const Orders: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const toast = useToast();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await getUserOrders();
                setOrders(response.data.orders);
            } catch (err) {
                setError('Failed to fetch orders. Please try again later.');
                toast({
                    title: "Error",
                    description: "Failed to fetch orders. Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrders();
    }, [toast]);

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const ImageCarousel: React.FC<{ images: Image[] }> = ({ images }) => {
        const [currentImageIndex, setCurrentImageIndex] = useState(0);

        const nextImage = () => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        };

        const prevImage = () => {
            setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        };

        return (
            <Box position="relative">
                <Box position="relative" paddingTop="66.67%">
                    <Image
                        src={images[currentImageIndex].url}
                        alt={`Image ${images[currentImageIndex].id}`}
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        objectFit="contain"
                    />
                </Box>
                {images.length > 1 && (
                    <HStack position="absolute" bottom="0" width="100%" justify="space-between" p={2}>
                        <IconButton
                            aria-label="Previous image"
                            icon={<BiChevronLeft />}
                            onClick={prevImage}
                            size="sm"
                        />
                        <Text>{`${currentImageIndex + 1} / ${images.length}`}</Text>
                        <IconButton
                            aria-label="Next image"
                            icon={<BiChevronRight />}
                            onClick={nextImage}
                            size="sm"
                        />
                    </HStack>
                )}
            </Box>
        );
    };

    if (isLoading) return (
        <Flex justify="center" align="center" height="100vh">
            <Spinner size="xl" color="brand.500" />
        </Flex>
    );

    if (error) return (
        <Container maxW="container.xl" py={12}>
            <Text color="red.500" fontSize="xl" textAlign="center">{error}</Text>
        </Container>
    );

    return (
        <Box bg="background.100" minHeight="100vh">
            <Container maxW="container.xl" py={12}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl" textAlign="center">Your Orders</Heading>
                    {orders.length === 0 ? (
                        <Text fontSize="xl" textAlign="center">You haven't placed any orders yet.</Text>
                    ) : (
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                            {orders.map((order) => (
                                <Box
                                    key={order.id}
                                    borderWidth={1}
                                    borderRadius="lg"
                                    overflow="hidden"
                                    boxShadow="md"
                                    bg="white"
                                >
                                    <ImageCarousel images={order.images} />
                                    <Box p={6}>
                                        <Flex justify="space-between" align="center" mb={2}>
                                            <Text fontWeight="bold" fontSize="lg">Order #{order.id}</Text>
                                            <Badge colorScheme={order.status === 'completed' ? 'green' : 'orange'}>
                                                {order.status}
                                            </Badge>
                                        </Flex>
                                        <Text mb={2}>Date: {formatDate(order.created_at)}</Text>
                                        <Text color="gray.600">Images: {order.images.length}</Text>
                                    </Box>
                                </Box>
                            ))}
                        </SimpleGrid>
                    )}
                </VStack>
            </Container>
        </Box>
    );
};

export default Orders;