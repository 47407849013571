import React, { useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    Input,
    Textarea,
    Button,
    Container,
    SimpleGrid,
    useToast,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import {sendUserMessage} from "../utils/api";

const Contact: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const toast = useToast();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Here you would typically send the form data to your backend
        await sendUserMessage(name, email, message)
        console.log('Form submitted:', { name, email, message });
        toast({
            title: "Message sent.",
            description: "We'll get back to you soon!",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        // Reset form
        setName('');
        setEmail('');
        setMessage('');
    };

    return (
        <Box bg="background.100" minHeight="100vh">
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    <VStack spacing={4} align="center" textAlign="center">
                        <Heading as="h1" size="2xl">
                            Contact Us
                        </Heading>
                        <Text fontSize="xl" maxW="2xl">
                            Have questions or suggestions? We'd love to hear from you!
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        <VStack spacing={8} align="stretch">
                            <Heading as="h2" size="lg">Send us a message</Heading>
                            <form onSubmit={handleSubmit}>
                                <VStack spacing={4}>
                                    <FormControl isRequired>
                                        <FormLabel>Your Name</FormLabel>
                                        <Input
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="John Doe"
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Your Email</FormLabel>
                                        <Input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="john@example.com"
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Your Message</FormLabel>
                                        <Textarea
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Your message here..."
                                            rows={5}
                                        />
                                    </FormControl>
                                    <Button type="submit" colorScheme="brand" size="lg" width="full" onClick={handleSubmit}>
                                        Send Message
                                    </Button>
                                </VStack>
                            </form>
                        </VStack>

                        <VStack spacing={8} align="stretch">
                            <Heading as="h2" size="lg">Other ways to reach us</Heading>
                            <VStack align="start" spacing={4}>
                                <Box display="flex" alignItems="center">
                                    <FaEnvelope color="brand.500" size="24px" />
                                    <Text ml={4}>alexroz0909@gmail.com</Text>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <FaPhone color="brand.500" size="24px" />
                                    <Text ml={4}>+1 (201) 286-5902</Text>
                                </Box>
                                {/*<Box display="flex" alignItems="center">*/}
                                {/*    <FaMapMarkerAlt color="brand.500" size="24px" />*/}
                                {/*    <Text ml={4}>123 AI Avenue, Tech City, TC 12345</Text>*/}
                                {/*</Box>*/}
                            </VStack>
                        </VStack>
                    </SimpleGrid>
                </VStack>
            </Container>
        </Box>
    );
}

export default Contact;