import React from 'react';
import { VStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {FaShoppingCart} from "react-icons/fa";

interface Image {
    id: number;
    url: string;
}

interface ImageCardProps {
    image: Image;
    onAddToCart: (image: Image) => void;
}

const ImageCard: React.FC<ImageCardProps> = ({ image, onAddToCart }) => {
    return (
        <VStack
            bg="white"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            spacing={0}
            align="stretch"
            transition="all 0.3s"
            _hover={{ boxShadow: 'xl', transform: 'translateY(-5px)' }}
        >
        <Box position="relative" paddingTop="75%"> // Changed from 66.67% to 75% for a larger image
            <Image
                src={image.url}
                alt={`Generated image ${image.id}`}
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                objectFit="cover"
            />
        </Box>
            <VStack p={5} spacing={3} align="stretch">
                <Text fontSize="sm" color="gray.500">Size: 24″×36″</Text>
                <Text fontWeight="bold" fontSize="lg" color="gray.800">$35.00</Text>
                <Button
                    leftIcon={<FaShoppingCart />}
                    colorScheme="brand"
                    onClick={() => onAddToCart(image)}
                    size="md"
                    width="100%"
                    mt={2}
                >
                    Add to Cart
                </Button>
            </VStack>
        </VStack>
    );
};


export default ImageCard;