import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    Button,
    Image,
    Flex,
    Spacer,
    Container,
    SimpleGrid,
    useToast, Divider, HStack,
} from '@chakra-ui/react';
import { useCart } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import CartItem from "./CartItem";

const Cart: React.FC = () => {
    const { items, removeItem, total, clearCart } = useCart();
    const navigate = useNavigate();
    const toast = useToast();

    const handleCheckout = () => {
        if (items.length === 0) {
            toast({
                title: "Cart is empty",
                description: "Please add items to your cart before checking out.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        } else {
            navigate('/checkout');
        }
    };

    return (
        <Box bg="gray.50" minHeight="100vh" py={12}>
            <Container maxW="container.md">
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl" textAlign="center" color="gray.700">
                        Your Cart
                    </Heading>
                    {items.length === 0 ? (
                        <Text fontSize="xl" textAlign="center" color="gray.500">
                            Your cart is empty.
                        </Text>
                    ) : (
                        <>
                            <VStack spacing={4} align="stretch">
                                {items.map((item) => (
                                    <CartItem key={item.id} item={item} onRemove={removeItem} />
                                ))}
                            </VStack>
                            <Divider />
                            <Flex justify="space-between" align="center" fontSize="xl">
                                <Text fontWeight="bold">Total:</Text>
                                <Text fontWeight="bold" color="brand.500">${total.toFixed(2)}</Text>
                            </Flex>
                            <HStack spacing={4} justify="space-between">
                                <Button
                                    variant="outline"
                                    colorScheme="gray"
                                    onClick={clearCart}
                                    size="lg"
                                >
                                    Clear Cart
                                </Button>
                                <Button
                                    colorScheme="brand"
                                    onClick={handleCheckout}
                                    size="lg"
                                    width="60%"
                                >
                                    Proceed to Checkout
                                </Button>
                            </HStack>
                        </>
                    )}
                </VStack>
            </Container>
        </Box>
    );
};



export default Cart;