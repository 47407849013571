import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    VStack,
    Heading,
    Text,
    Input,
    Button,
    Flex,
    Image,
    Container,
    SimpleGrid,
    useToast, Icon, keyframes,
} from '@chakra-ui/react';
import {generateImages, subscribeToNewsLetter} from "../utils/api";
import {FaMagic, FaPencilAlt, FaShoppingCart} from "react-icons/fa";
import SearchBar from "../components/SearchBar";

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

interface ImagePromptProps {
    imageUrl: string;
    prompt: string;
}

const ImagePrompt: React.FC<ImagePromptProps> = ({ imageUrl, prompt }) => (
    <Box
        bg="gray.800"
        color="white"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="md"
        height="100%"
        display="flex"
        flexDirection="column"
        _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
    >
        <Box position="relative" paddingTop="100%">
            <Image
                src={imageUrl}
                alt={prompt}
                objectFit="cover"
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
            />
        </Box>
        <Box flex={1} p={6}>
            <Text fontSize="md">{prompt}</Text>
        </Box>
    </Box>
);



const FeatureCard: React.FC<{ icon: React.ElementType; title: string; description: string }> = ({ icon, title, description }) => (
    <VStack
        bg="white"
        p={6}
        borderRadius="lg"
        boxShadow="md"
        align="start"
        spacing={4}
        transition="all 0.3s"
        _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
    >
        <Icon as={icon} w={10} h={10} color="brand.500" />
        <Text fontWeight="bold" fontSize="xl" color="gray.800">{title}</Text>
        <Text color="gray.600">{description}</Text>
    </VStack>
);


interface TestimonialProps {
    quote: string;
    author: string;
    role: string;
}

const Testimonial: React.FC<TestimonialProps> = ({ quote, author, role }) => (
    <Box
        bg="white"
        p={6}
        borderRadius="lg"
        boxShadow="md"
        height="100%"
    >
        <Text fontSize="md" fontStyle="italic" mb={4}>"{quote}"</Text>
        <Text fontWeight="bold">{author}</Text>
        <Text fontSize="sm" color="gray.600">{role}</Text>
    </Box>
);


const Home: React.FC = () => {
    const [prompt, setPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [newsLetterEmail, setNewsLetterEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const handleGenerate = async () => {
        if (!prompt) {
            toast({
                title: "Empty prompt",
                description: "Please enter a description for your image.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setIsLoading(true);
        try {
            const response = await generateImages(prompt);
            navigate('/generated-images', {
                state: {
                    images: response.map((img: any) => ({
                        id: img.id,
                        url: img.url
                    })),
                    prompt: prompt // Pass the prompt to the next screen
                }
            });
        } catch (error) {
            console.error('Failed to generate images:', error);
            toast({
                title: "Error",
                description: "Failed to generate images. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewsLetter = async () => {
        if (!newsLetterEmail) {
            toast({
                title: "Enter email",
                description: "Please enter your email to subcribe to the newsletter.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        try {
            await subscribeToNewsLetter(newsLetterEmail);

            setIsSubmitted(true);
            setNewsLetterEmail('');
            toast({
                title: "Subscribed!",
                description: "You've been successfully subscribed to our newsletter.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Newsletter subscription error:", error);
            toast({
                title: "Subscription failed",
                description: "An error occurred while subscribing. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitted(false);
        }
    }

    return (
        <Box bg="background.100" minHeight="100vh">
            <Container maxW="container.xl" py={12}>
                <VStack spacing={16}>
                    {/* Hero Section */}
                    <Box
                        w="full"
                        backgroundImage="linear-gradient(to right, #ff7e5f, #feb47b)"
                        borderRadius="xl"
                        p={12}
                    >
                        <VStack spacing={8} align="center">
                            <Heading as="h1" size="3xl" textAlign="center" color="white">
                                Be your own Artist with AI
                            </Heading>
                            <Text fontSize="xl" textAlign="center" maxW="2xl" color="white">
                                Describe the photo you want to see, and AI will generate it!
                            </Text>
                            <Flex w="full" maxW="2xl">
                                <SearchBar
                                    prompt={prompt}
                                    setPrompt={setPrompt}
                                    handleGenerate={handleGenerate}
                                    isLoading={isLoading}
                                />
                            </Flex>
                        </VStack>
                    </Box>

                    {/* How It Works Section */}
                    <Box>
                        <Heading as="h2" size="xl" textAlign="center" mb={8}>
                            How It Works
                        </Heading>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                            <FeatureCard
                                icon={FaPencilAlt}
                                title="Describe"
                                description="Write a detailed description of the image you want to create."
                            />
                            <FeatureCard
                                icon={FaMagic}
                                title="Generate"
                                description="Our AI transforms your description into a unique piece of art."
                            />
                            <FeatureCard
                                icon={FaShoppingCart}
                                title="Purchase"
                                description="Choose your favorite generated image and make it yours."
                            />
                        </SimpleGrid>
                    </Box>

                    {/* Example Creations Section */}
                    <Box width="full">
                        <Heading as="h2" size="xl" textAlign="center" mb={8}>
                            Example Creations
                        </Heading>
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                            <ImagePrompt
                                imageUrl="/home-page-imgs/mars_cowboy1.png"
                                prompt="A cowboy exploring Mars, contemplating his time on Earth in a general impressionistic style"
                            />
                            <ImagePrompt
                                imageUrl="/home-page-imgs/modernist_fruits1.png"
                                prompt="A table with a vase filled with fruits like pomegranates, apples, and grapes in the style of modernism"
                            />
                            <ImagePrompt
                                imageUrl="/home-page-imgs/puppy1.png"
                                prompt="A portrait of a puppy studying for the bar in an old library"
                            />
                        </SimpleGrid>
                    </Box>
                    {/* Testimonials Section */}
                    <Box width="full">
                        <Heading as="h2" size="xl" textAlign="center" mb={8}>
                            What Our Customers Say
                        </Heading>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                            <Testimonial
                                quote="The AI-generated art has transformed my office. Patients love the unique, calming atmosphere it creates. It's a great conversation starter and helps put everyone at ease."
                                author="Dr. Sarah Johnson"
                                role="Family Physician"
                            />
                            <Testimonial
                                quote="As a college student on a budget, this was perfect for decorating my dorm. The posters are high-quality and my roommates can't believe I designed them myself!"
                                author="Mike Chen"
                                role="College Student"
                            />
                            <Testimonial
                                quote="These AI-generated posters were the highlight of my party! They added a unique and artistic touch to the decor, impressing all my guests. It's amazing how easy it was to customize and order them!"
                                author="Sophia Ridley"
                                role="Event Planner"
                            />
                        </SimpleGrid>
                    </Box>
                    {/* Newsletter Signup */}
                    <Box bg="brand.500" w="full" py={12} borderRadius="lg">
                        <VStack spacing={4}>
                            <Heading as="h2" size="xl" textAlign="center" color="white">
                                Stay Inspired
                            </Heading>
                            <Text fontSize="lg" textAlign="center" color="white" maxW="2xl">
                                Sign up for our newsletter to receive weekly art prompts and stay updated on new features.
                            </Text>
                            <Flex maxW="md" w="full" direction="column">
                                <Flex>
                                    <Input
                                        value={newsLetterEmail}
                                        onChange={(e) => setNewsLetterEmail(e.target.value)}
                                        placeholder="Your email address"
                                        bg="white"
                                        size="lg"
                                        mr={2}
                                    />
                                    <Button
                                        size="lg"
                                        bg="gray.800"
                                        color="white"
                                        _hover={{ bg: "gray.700" }}
                                        onClick={handleNewsLetter}
                                    >
                                        {isSubmitted ? 'Subscribed!' : 'Subscribe'}
                                    </Button>
                                </Flex>
                            </Flex>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}

export default Home;