import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../utils/api';
import { Box, Button, FormControl, FormLabel, Input, VStack, Text, FormErrorMessage } from '@chakra-ui/react';
import { AuthContext } from "../contexts/AuthContext";

const Register: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setEmailError('');
        setPasswordError('');
        setError('');

        let isValid = true;

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            isValid = false;
        }

        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            isValid = false;
        }

        if (!isValid) return;

        try {
            const response = await register(email, password);
            login(response.data.token);
            navigate('/');
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <Box maxWidth="400px" margin="auto" mt={8}>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl isInvalid={!!emailError}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <FormErrorMessage>{emailError}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!passwordError}>
                        <FormLabel>Password</FormLabel>
                        <Input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <FormErrorMessage>{passwordError}</FormErrorMessage>
                    </FormControl>
                    <Button type="submit" colorScheme="blue" width="full">Register</Button>
                </VStack>
            </form>
            {error && <Text color="red.500" mt={4}>{error}</Text>}
        </Box>
    );
};

export default Register;