// src/components/Login.tsx
import React, {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { loginApi } from '../utils/api';
import { Box, Button, FormControl, FormLabel, Input, VStack, Text } from '@chakra-ui/react';
import {AuthContext, useAuth} from "../contexts/AuthContext";


const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await loginApi(email, password);
            login(response.data.token);
            navigate('/');
        } catch (err) {
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <Box maxWidth="400px" margin="auto" mt={8}>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </FormControl>
                    <Button type="submit" colorScheme="blue" width="full">Login</Button>
                </VStack>
            </form>
            {error && <Text color="red.500" mt={4}>{error}</Text>}
        </Box>
    );
};

export default Login ;