import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    Button,
    Icon,
    Image,
    Container,
    Flex,
    Divider,
    useColorModeValue
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

// Define the interface for an item
interface OrderItem {
    id: number;
    url: string;
    price: number;
}

// Define the interface for the location state
interface LocationState {
    items: OrderItem[];
    total: number;
    orderId: string;
}

const OrderSuccess: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { items, total, orderId } = (location.state as LocationState) || { items: [], total: 0, orderId: 'N/A' };
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    const cardBgColor = useColorModeValue('white', 'gray.700');

    return (
        <Box bg={bgColor} minHeight="100vh" py={12}>
            <Container maxW="container.md">
                <VStack spacing={8} align="center">
                    <Icon as={FaCheckCircle} w={16} h={16} color="green.500" />
                    <Heading as="h1" size="2xl" textAlign="center" color="green.500">
                        Order Placed Successfully!
                    </Heading>
                    <Text fontSize="xl" textAlign="center">
                        Thank you for your purchase. Your AI-generated art poster will be on its way soon! You will get an order confirmation email shortly.
                    </Text>
                    <Box
                        w="full"
                        bg={cardBgColor}
                        borderRadius="lg"
                        p={6}
                        boxShadow="md"
                    >
                        <Heading as="h2" size="lg" mb={4}>
                            Order Summary
                        </Heading>
                        <Divider mb={4} />
                        {items.map((item: OrderItem) => (
                            <Flex key={item.id} mb={4} alignItems="center" justifyContent="space-between">
                                <Flex alignItems="center">
                                    <Image
                                        src={item.url}
                                        boxSize="80px"
                                        objectFit="cover"
                                        borderRadius="md"
                                        mr={4}
                                    />
                                    <Box>
                                        <Text fontSize="sm" color="gray.500">Size: 18"×24"</Text>
                                    </Box>
                                </Flex>
                                <Text fontWeight="semibold">${item.price.toFixed(2)}</Text>
                            </Flex>
                        ))}
                        <Divider my={4} />
                        <Flex justifyContent="space-between" fontWeight="bold">
                            <Text>Total</Text>
                            <Text>${total.toFixed(2)}</Text>
                        </Flex>
                    </Box>
                    <Button
                        colorScheme="orange"
                        size="lg"
                        onClick={() => navigate('/')}
                    >
                        Return to Home
                    </Button>
                </VStack>
            </Container>
        </Box>
    );
};

export default OrderSuccess;