import React from 'react';
import { Box, Grid, Heading, Text, Button, VStack, Flex, useToast } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import ImageCard from "../components/ImageCard"; // Assuming you have a CartContext

interface Image {
    id: number;
    url: string;
}

interface LocationState {
    images: Image[];
    prompt: string;
}

const GeneratedImages: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { images, prompt } = location.state as LocationState;
    const { addItem } = useCart(); // Assuming you have this hook in your CartContext
    const toast = useToast();

    const handleAddToCart = (image: Image) => {
        addItem({
            id: image.id,
            url: image.url,
            price: 35.00, // Assuming a fixed price of $35.00
        });
        toast({
            title: "Added to cart",
            description: "The image has been added to your cart.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box bg="gray.50" minHeight="100vh" padding={8}>
            <Flex direction="column" align="center" maxWidth="1200px" margin="auto">
                <Heading as="h1" size="xl" mb={6}>
                    Choose Your Favorite Images
                </Heading>
                <Text fontSize="lg" mb={8}>
                    Click "Add to Cart" below each image you'd like to purchase.
                </Text>
                <Grid
                    templateColumns={["1fr", "1fr 1fr", "1fr 1fr"]} // Changed from "repeat(4, 1fr)" to "1fr 1fr"
                    gap={8} // Increased from 6 to 8
                    mb={8}
                    width="100%"
                >
                    {images.map((image) => (
                        <ImageCard
                            key={image.id}
                            image={image}
                            onAddToCart={handleAddToCart}
                        />
                    ))}
                </Grid>
                <Button
                    colorScheme="blue"
                    size="lg"
                    onClick={() => navigate('/cart')}
                >
                    Go to Cart
                </Button>
            </Flex>
        </Box>
    );
};

export default GeneratedImages;