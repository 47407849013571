import React from 'react';
import { Flex, Input, Button, FlexProps } from '@chakra-ui/react';

interface SearchBarProps extends Omit<FlexProps, 'onChange'> {
    prompt: string;
    setPrompt: (value: string) => void;
    handleGenerate: () => void;
    isLoading: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
                                                 prompt,
                                                 setPrompt,
                                                 handleGenerate,
                                                 isLoading,
                                                 ...flexProps
                                             }) => (
    <Flex
        w="full"
        maxW="3xl"
        flexDirection={{ base: "column", md: "row" }}
        alignItems="stretch"
        {...flexProps}
    >
        <Input
            placeholder="A dreamy elephant riding a unicycle in an impressionist style"
            value={prompt}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrompt(e.target.value)}
            size="lg"
            mr={{ base: 0, md: 4 }}
            mb={{ base: 4, md: 0 }}
            bg="white"
            height="60px"
            fontSize="md"
            flex={{ md: 1 }} // Allow input to grow and fill available space
        />
        <Button
            bg="black" // Changed to black background
            color="white" // White text
            size="lg"
            onClick={handleGenerate}
            isLoading={isLoading}
            loadingText="Generating"
            height="60px"
            minWidth={{ base: "full", md: "120px" }}
            _hover={{ bg: "gray.800" }} // Slightly lighter on hover for better UX
        >
            Generate
        </Button>
    </Flex>
);

export default SearchBar;