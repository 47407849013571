import React from 'react';
import { Box, VStack, Heading, Text, Image, Container, SimpleGrid, Icon } from '@chakra-ui/react';
import { FaPaintBrush, FaRobot, FaUsers } from 'react-icons/fa';

interface FeatureProps {
    icon: React.ElementType;
    title: string;
    description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
    <VStack align="start" spacing={4}>
        <Icon as={icon} w={10} h={10} color="brand.500" />
        <Heading as="h3" size="md">{title}</Heading>
        <Text>{description}</Text>
    </VStack>
);

const About: React.FC = () => {
    return (
        <Box bg="background.100" minHeight="100vh">
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    <VStack spacing={4} align="center" textAlign="center">
                        <Heading as="h1" size="2xl">
                            About Mona
                        </Heading>
                        <Text fontSize="xl" maxW="2xl">
                            Mona is an innovative AI-powered platform that brings your artistic visions to life.
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} alignItems="center">
                        <Image
                            src="/mona_logo_1.png"
                            alt="Mona AI Art"
                            borderRadius="md"
                            boxShadow="lg"
                        />
                        <VStack align="start" spacing={4}>
                            <Heading as="h2" size="xl">Our Mission</Heading>
                            <Text fontSize="lg">
                                Founded in 2024, Mona aims to democratize art creation and empower individuals to
                                express their creativity without traditional artistic constraints. Whether you're a
                                professional looking for inspiration or someone who's always wanted to create but lacked
                                the technical skills, Mona is here to help you realize your artistic dreams.
                            </Text>
                        </VStack>
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                        <Feature
                            icon={FaPaintBrush}
                            title="Unleash Creativity"
                            description="Transform your ideas into stunning visual art with just a text description."
                        />
                        <Feature
                            icon={FaRobot}
                            title="Cutting-edge AI"
                            description="Our AI model is trained on millions of images across various artistic styles."
                        />
                        <Feature
                            icon={FaUsers}
                            title="For Everyone"
                            description="From professionals to hobbyists, Mona makes art creation accessible to all."
                        />
                    </SimpleGrid>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Our Technology</Heading>
                        <Text fontSize="lg">
                            Our AI model has been trained on millions of images across various artistic styles,
                            enabling it to understand and interpret complex descriptions. From abstract concepts
                            to detailed scenes, Mona can bring your ideas to life with astonishing accuracy and beauty.
                        </Text>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Join the Creative Revolution</Heading>
                        <Text fontSize="lg">
                            Join us on this exciting journey of AI-powered creativity. With Mona, the only limit
                            is your imagination! Start creating today and experience the future of digital art.
                        </Text>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}

export default About;